#nav-ul-data-menu { 
	a.nav-link.active {
		font-weight: bold;
		text-decoration: underline;
	}

	a.nav-link {
		color: #007bff;
	}
}

.ag-theme-balham .ag-row.prodeval-row-class {
	background-color: #c4e0a2;
}
