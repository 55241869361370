#settings-menu {

    padding: 1.5rem 0;

    li {
        padding: .25rem 1.5rem;
        width: 100%;

        a.active {
            font-weight: bold;
            text-decoration: underline;
        }
    }

}


#permissions{

    min-width: 768px;

    .list-group-item{

        .input-group-text{

            margin-top:-1px;
            margin-left:-1px;
            margin-bottom: -1px;

        }

    }

}

#storages-grid{
    height: 200px;
}