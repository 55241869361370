#stock{

    #grid-dashboard{

        .ag-row-level-0 {
            background-color: #fff !important;
        }
        .ag-row-level-1 {
            background-color: #eee !important;
        }
        .ag-row-level-2 {
            background-color: #ddd !important;
        }
    }  

}