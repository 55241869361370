@use "sass:math";

table-editor {
	$btnSize: 30px;
	$pinBtnWidth: 43px;

	button.btn-sm {
		width: $btnSize;
		height: $btnSize;
	}

	.btn-pin-right {
		height: $btnSize;
		width: $pinBtnWidth !important;
		padding: 0;
		padding-right: $pinBtnWidth - $btnSize;
		mask-image: url("../img/pin-right.svg");
	}

	.btn-pin-left {
		height: $btnSize;
		width: $pinBtnWidth !important;
		padding: 0;
		padding-left: $pinBtnWidth - $btnSize;
		mask-image: url("../img/pin-left.svg");
	}

	&.expanded {
		.type-cell.input-type {
			max-width: 354px !important;
			width: 354px !important;
			font-size: 1em;
			.select2.select2-container {
				width: 350px !important;
			}
		}
	}

	table {
		border-collapse: collapse;

		width: 100%;
		//height: 100%;
		text-align: center;

		td,
		th {
			border: 1px solid var(--ap-grey-200);
		}

		th.settings {
			width: 125px;
		}

		th.spacer {
			width: 10px;
		}

		th.remove {
			width: 35px;
			opacity: 0;
			visibility: hidden;
			border: 0 !important;
		}
		th.task {
			width: 175px;
		}

		td.spacer,
		th.spacer {
			background-color: white !important;
			border: 0;
		}

		thead,
		tfoot {
			background-color: var(--ap-grey-100);
		}

		tr.ghost {
			border: 3px dashed var(--ap-blue-500);
		}


		.settings-cell {
			position: relative;
			.add-before-row-btn {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;

				margin-top: 0 - math.div($btnSize, 2);
				margin-left: 0 - $pinBtnWidth;
			}
			.add-after-row-btn {
				position: absolute;
				top: 100%;
				left: 0;
				opacity: 0;

				margin-top: 0 - math.div($btnSize, 2);
				margin-left: 0 - $pinBtnWidth;
			}
		}

		td.type-cell.input-type {
			width: 204px !important;
			max-width: 204px !important;
			font-size: 0.8em;
			.select2.select2-container {
				width: 200px !important;
			}
		}

		td.type-cell.description {
			span {
				display: block; /* or inline-block */
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				max-height: 2em;
				line-height: 1em;
			}
		}

		tbody {
			tr:hover /*, tr:hover+tr*/ {
				.add-before-row-btn,
				.add-after-row-btn {
					opacity: 1;
				}

				td {
					background-color: var(--ap-blue-100);
				}
			}
		}

		.title1,
		.title2,
		.title3,
		.text {
			text-align: center;
			background-color: rgba(255, 255, 255, 0.75);
			margin: 2px;
			width: calc(100% - 4px) !important;
			padding: 0;
			line-height: 1;
		}

		.title1 {
			font-size: 1.5rem;
		}
		.title2 {
			font-size: 1.3rem;
		}
		.title3 {
			font-size: 1.2rem;
		}

		tr:first-child {
			.type-cell {
				border-top: 2px solid black !important;
			}
		}

		tr:last-child {
			.type-cell {
				border-bottom: 2px solid black !important;
			}
		}

		tr.spacer {
			.type-cell {
				border-top: 2px solid black !important;
				border-bottom: 2px solid black !important;
			}
		}

		tr.spacer {
			td.spacer.type-cell {
				border-left: 0 !important;
				border-right: 0 !important;
			}
			.spacer {
				border-left: 2px solid white !important;
				border-right: 2px solid white !important;
			}
		}

		col.first {
			border-left: 2px solid black;
		}
		col.last {
			border-right: 2px solid black;
		}

		.remove-cell {
			background-color: white !important;
			border: 0 !important;
		}
	}
}
