.ag-row-danger {
    color: #7f231c !important;
    background-color: #fdd9d7 !important;
    border-color: #fccac7 !important;
}
.ag-row-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}
.ag-row-success {
    color: #285b2a !important;
    background-color: #dbefdc !important;
    border-color: #cde9ce !important;
}
.ag-row-dark {
    color: #1b1e21 !important;
    background-color: #d6d8d9 !important;
    border-color: #c6c8ca !important;
}
.ag-row-info {
    color: #005964 !important;
    background-color: #cceef3 !important;
    border-color: #b8e8ee !important;
}
.ag-row-purple {
    color: #51145c !important;
    background-color: #f3e5f5 !important;
    border-color: #e3c3e9 !important;
}

.ag-row-red{
	$color: #ffcdd2;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-pink{
	$color: #f8bbd0;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-purple{
	$color: #e1bee7;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-deep-purple{
	$color: #d1c4e9;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-indigo{
	$color: #c5cae9;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-blue{
	$color: #bbdefb;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-light-blue{
	$color: #b3e5fc;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-cyan{
	$color: #b2ebf2;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-teal{
	$color: #b2dfdb;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-green{
	$color: #c8e6c9;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-light-green{
	$color: #dcedc8;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-lime{
	$color: #f0f4c3;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-yellow{
	$color: #fff9c4;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-amber{
	$color: #ffecb3;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-orange{
	$color: #ffe0b2;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-deep-orange{
	$color: #ffccbc;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-brown{
	$color: #d7ccc8;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-grey{
	$color: #f5f5f5;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}
.ag-row-blue-grey{
	$color: #cfd8dc;
	background:  $color !important;
	color: darken($color, 50%) !important;
	border-color: darken($color, 10%) !important;
	}

/*
.ag-row-level-0.ag-row-group-expanded .ag-cell, .ag-row-level-1 .ag-cell{
    background: rgba(0,0,0,0.05);
}

.ag-row-level-1.ag-row-group-expanded .ag-cell, .ag-row-level-2 .ag-cell{
    background: rgba(0,0,0,0.075);
}

.ag-row-level-2.ag-row-group-expanded .ag-cell, .ag-row-level-3 .ag-cell{
    background: rgba(0,0,0,0.1);
}*/

#grid-orderProducts{
    height: 200px;
}

#grid-deliveredProducts{
    height: 200px;
}

#grid-equipments{
    height: 200px;
}

#grid-selectOrder{
    height: 600px;
}

.btn-round-small {
    border-radius: 999em;
    width: 25px;
    height: 25px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    line-height:1;
    font-size: 18px;
    position: relative;
    cursor: pointer;
    display: inline-block;
  
  }

//   #grid-workOrder .ag-row-group{
//       font-weight: bold;
//   }

//   #grid-billing.ag-row-group{
//     font-weight: bold;
// }