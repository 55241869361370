input {
-webkit-appearance: none;
outline: none;
}


.circle {
border-radius: 50%;
height: 2.2rem;
width: 2.2rem;
font-size: 30px;
}


.fix-ag-grid-container{
    position: relative
}

.fix-ag-grid{

    position: absolute;
     top:0;
    left:0;
     right:0;
      bottom:0;

}

.border-profil {
    padding: 4%;
}

.pad-cooler {
    padding: 15%;
}

.form-control.custom{
    border : 1px solid #ced4da;
}

.modal-75 {
    max-width: 75%!important;
}

.modal-90 {
    max-width: 90%!important;
}

.sticky-top {
    background-color: white;
}

.form-control-perso {
    display: block;
    width: 100%;
    padding: 0.3rem 0.3rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 0;
    box-shadow: none;
    text-align: center;
    margin-left: 10px;
    padding-left: 10px;
}

.form-control-perso-2 {
    display: block;
    width: 100%;
    padding: 0.3rem 0.3rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 0;
    box-shadow: none;

}

.fix-date-picker{
   width: 208px !important;
}

.publicHoliday:not(.ag-row-selected){
    color: #7f231c;
    background-color: #fdd9d7!important;
    border-color: #fccac7;
}

.sunday:not(.ag-row-selected){
    color: #51145c;
    background-color: #f3e5f5!important;
    border-color: #e3c3e9;
}

.locked:not(.ag-row-selected){
    color: #324148;
    background-color: #eceff1!important;
    border-color: #d2dbdf;
}

.form-control {
    border: 1px solid #d4d7db;
    box-shadow: none;
}

#see-more p {
    margin-top: -1rem;
}

.fix-margin{
    margin-left: -0.88rem;
}



.profile-picture-container{
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
}

.profile-picture-container:before{
    content: "";
    display: block;
    padding-top: 100%; 
}

.profile-picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 0.6rem;
}

.carousel a {
    background-color: black;
}

.carousel-control-prev, .carousel-control-next {
     height: 2rem;
     width: 2rem;
     
    
}


input[type="time"]::-webkit-clear-button,
input[type="time"]::-moz-clear {
    display: none;
}

td .form-control {
    padding: 0;
}

.table th, .table td {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-top: none;
}

.table thead th {
    border-bottom:none; 
}

.modal-body {
    padding: 2rem;
}

.modal-body .col-lg-5 {
    padding-right: 0;
    padding-left: 0;
}

.modal-body .col-lg-7 {
    padding-right: 0;
    padding-left: 0;
}

td .form-control-plaintext {
    padding-top: 0;
    padding-bottom: 0;
}
