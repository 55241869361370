/*************************************************************************************************************/
/*                                             Coulor for tabs                                               */
/*************************************************************************************************************/

.ag-row-low-grey, .alert-low-grey {
	background-color: #bfbfbf !important;
}

.ag-row-teal-blue, .alert-teal-blue {
	background-color: #78dbff !important;
}

.ag-row-forest-green,.alert-forest-green {
	background-color: #58ffa3 !important;
}

.ag-row-salmon, .alert-salmon {
	background-color:  #ffb2b4 !important;
}

.ag-row-sun, .alert-sun {
	background-color: #ffdf7f !important;
}