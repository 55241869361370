.bill-detail-modal {
  .orders-numbers,
  .delivery-notes-numbers,
  .work-orders-numbers {
    max-height: 100px;
    overflow: auto;
  }

  .badge {
    background-color: #e3e3e3;
    font-weight: normal;
    border-color: #c6c4c4;
  }
}
