$title1Height: 40px;
$title1FontSize: 1.75rem;

$title2Height: 40px;
$title2FontSize: 1.5rem;

$title3Height: 35px;
$title3FontSize: 1.25rem;

table-checklist {

    table {
        width: 100%;
    }

    text-align: center;
    width: 100%;

    .row-title1 {
        
        td:nth-child(2) {
            padding-left: 15px;
            line-height: $title1Height;
            height: $title1Height;
            font-size: $title1FontSize;
        }

    }

    .row-title2 {
        
        td:nth-child(2) {
            padding-left: 15px;
            line-height: $title2Height;
            height: $title2Height;
            font-size: $title2FontSize;
        }
    }

    .row-title3 {
        
        td:nth-child(2) {
            padding-left: 15px;
            line-height: $title3Height;
            height: $title3Height;
            font-size: $title3FontSize;
        }
    }

    .row-text,
    .row-number {
        input {
            width: 100%;
        }
    }

    thead,
	tbody {
		border: 2px solid black;
	}

	tr.spacer {
		td {
			height: 20px;
		}
	}
	thead,
	.title1,
	.title2,
	.title3 {
		background-color: var(--ap-grey-100);
	}

	.title1 {
		font-size: 1.5rem;
	}
	.title2 {
		font-size: 1.3rem;
	}
	.title3 {
		font-size: 1.2rem;
	}

	tr:not(.spacer) {
		td,
		th {
			border: 1px solid var(--ap-grey-400);
			padding: 0.25rem;
		}
	}
}
