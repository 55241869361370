.btn-state-to-confirm {
	color: white;
	background-color: #660066;
}

.ag-theme-balham .ag-row.ag-row-to-confirm {
	background-color: #d783d7;
}

.btn-state-pre-validate {
	color: white;
	background-color: #ff66ff;
}

.ag-theme-balham .ag-row.ag-row-pre-validate {
	background-color: #f8c2f8;
}

.btn-state-archived {
	color: #777;
	background-color: #ddd;
	font-style: italic;
}

.ag-theme-balham .ag-row.ag-row-archived {
	color: #777;
	background-color: #ddd;
	font-style: italic;
}

.btn-state-not-archived {
	// background-color: ;
}

.ag-theme-balham .ag-row.ag-row-not-archived {

}

.btn-state-not-delivered {
	color: #5d4a43;
	background-color: #c2b1aa;
}

.ag-theme-balham .ag-row.ag-row-not-delivered {
	color: #5d4a43;
	background-color: #c2b1aa;
}

.btn-state-in-delivering {
	color: #b38600;
	background-color: #ffdf80;
}

.ag-theme-balham .ag-row.ag-row-in-delivering {
	color: #b38600;
	background-color: #ffdf80;
}

.btn-state-delivered {
	color: #5f892d;
	background-color: #c4e0a2;
}

.ag-theme-balham .ag-row.ag-row-delivered {
	color: #5f892d;
	background-color: #c4e0a2;
}

.btn-state-not-billed {
	color: #7f231c;
	background-color: #ffebee;
}

.ag-theme-balham .ag-row.ag-row-not-billed {
	color: #7f231c;
	background-color: #ffebee;
}

.btn-state-in-billing {
	
}

.ag-theme-balham .ag-row.ag-row-in-billing {

}

.btn-state-billed {
	color: #18808d;
	background-color: #86e0eb;
}

.ag-theme-balham .ag-row.ag-row-billed {
	color: #18808d;
	background-color: #86e0eb;
}

.btn-state-waiting {
	color: black;
	background-color: #aaa;
}

.ag-theme-balham .ag-row.ag-row-waiting {
	color: black;
	background-color: #aaa;
}


.btn-state-recorded {

}

.ag-theme-balham .ag-row.ag-row-recorded {

}

.btn-state-validated {
	color: black;
	background-color: #78dbff;
}

.ag-theme-balham .ag-row.ag-row-validated {
	color: black;
	background-color: #78dbff;
}

.btn-state-pre-validated {

}

.ag-theme-balham .ag-row.ag-row-pre-validated {

}

.btn-state-locked {
	color: black;
	background-color: #ffb2b4;
}

.ag-theme-balham .ag-row.ag-row-locked {
	color: black;
	background-color: #ffb2b4;
}

.btn-state-paid {
	color: black;
	background-color: #58ffa3;
}

.ag-theme-balham .ag-row.ag-row-paid {
	color: black;
	background-color: #58ffa3;
}

.btn-state-paying {

}

.ag-theme-balham .ag-row.ag-row-paying {

}

.btn-state-cancel-payment {
	color: black;
	background-color: #ffdf7f;
}

.ag-theme-balham .ag-row.ag-row-cancel-payment {
	color: black;
	background-color: #ffdf7f;
}

