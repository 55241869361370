#login-logo {
	margin-top: -110px;
	height: 200px;
	z-index: 99;

	img {
		height: 100%;
		border: 4px solid white;
	}

	margin-bottom: 1rem;
}
