@import "toastr/toastr.scss";
@import "select2/src/scss/core.scss";

@import "login.scss";

.fix-ag-grid-container {
	position: relative;
}

.fix-ag-grid {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.text-vertical {
	writing-mode: vertical-rl;
}

.ag-theme-balham {
	.ag-header-cell.multiline .ag-header-cell-text {
		text-overflow: clip;
		overflow: visible;
		white-space: normal;
		height: auto;
	}

	.ag-column-hover {
		background-color: #ecf0f1;
	}

	.ag-row {
		line-height: 26px;
	}

	.ag-cell {
		line-height: inherit !important;
	}

	.ag-header-cell {
		padding-left: 3px !important;
		padding-right: 3px !important;
		line-height: 1.2 !important;
		text-align: center;

		&.text-vertical {
			padding-left: 0px !important;
			padding-right: 0px !important;
			padding-top: 3px !important;
			padding-bottom: 3px !important;
		}
	}

	.ag-cell-label-container {
		width: 100%;
		height: 100%;
	}

	.ag-header-cell-label,
	.ag-header-group-cell-label {
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		.ag-header-expand-icon {
			height: 100% !important;
		}
	}

	.ag-header-icon {
		height: auto !important;
	}

	.ag-fake-header,
	.ag-fake-vertical-header {
		background-color: #f5f7f7;
		color: rgba(0, 0, 0, 0.54);
		font-weight: 600;
		font-size: 12px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
			"Helvetica Neue", sans-serif;
		display: flex;
		border: 1px solid #bdc3c7;
		justify-content: center;
	}

	.ag-fake-header {
		height: 32px;
		line-height: 32px;
		padding-left: 12px;
		padding-right: 12px;
		border-bottom: 0;
	}

	.ag-fake-vertical-header {
		width: 32px;
		line-height: 32px;
		padding-top: 12px;
		padding-bottom: 12px;
		border-left: 0;
		writing-mode: vertical-lr;
		transform: rotate(180deg);
	}

	.ag-ltr {
		.ag-floating-filter-button {
			margin-left: 0 !important;
		}
	}

	.ag-floating-filter-body {
		align-items: center;
	}

	.ag-filter-header-container {
		.ag-filter-checkbox {
			display: inline-block;
			margin-left: 5px;
		}
		.ag-filter-value {
			margin-left: 5px;
		}
	}

	.ag-side-buttons {
		.ag-icon-columns {
			display: none;
		}
	}

	.ag-column-drag {
		font-family: "agGridBalham";
		display: block;
		// speak: none;
		font-size: 16px;
		color: #7f8c8d;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		&:before {
			content: "";
		}
	}

	.ag-menu {
		z-index: 1; //Permet d'évité que le tooltip passe devant le menu contextuel
	}
}

.round-right {
	border-top-right-radius: 999em;
	border-bottom-right-radius: 999em;
	line-height: 0.5;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	position: relative;
	cursor: pointer;
	display: inline-block;
}

.round-left {
	border-top-left-radius: 999em;
	border-bottom-left-radius: 999em;
	line-height: 0.5;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	position: relative;
	cursor: pointer;
	display: inline-block;
}

.ag-dnd-ghost {
	z-index: 1000000;
}

.ag-watermark {
	display: none;
}

.btn-round {
	border-radius: 50%;
	width: 38px;
	height: 38px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	font-size: 1.25rem;
	position: relative;
	cursor: pointer;
	display: inline-block;
}

.btn-round-small {
	border-radius: 50%;
	width: 25px;
	height: 25px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	font-size: 1.25rem;
	position: relative;
	cursor: pointer;
	display: inline-block;
}

button:focus {
	outline: none !important;
}

.small {
	font-size: 0.7rem;
}

input[type="checkbox"] {
	-webkit-appearance: checkbox;
}

.ag-row-error {
	color: #7f231c;
	border-color: #7f231c !important;
	background-color: #fccac7 !important;
}
.ag-cell {
	padding-left: 5px !important;
	padding-right: 5px !important;
}
/*
.ag-header-cell {
	padding-left: 6px !important;
	padding-right: 6px !important;

	.ag-cell-label-container {
		display: flex;
	}

	.ag-header-cell-menu-button {
		float: none;
		order: 1;
	}

	.ag-header-cell-label {
		width: 100% !important;
	}
}*/

.btn-tab {
	&.active {
		background: white;
		z-index: 100 !important;
		position: relative;
		&:after {
			position: absolute;
			content: "";
			width: 100%;
			height: 6px;
			background: white;
			top: 100%;
			left: 0;
		}
	}
}

.full-screen {
	z-index: 999998;
}

.print-event {
	opacity: 0.5;
	position: absolute;
	left: 0;
	bottom: 2px;

	th,
	td {
		font-size: 8px;
		border: 1px solid black;
	}

	.title {
		font-size: 15px;
	}
}

.tabs-container {
	overflow: hidden !important;
	white-space: nowrap;
}

#page-title {
	.title {
		white-space: nowrap;
	}
}

.aside-logo {
	display: none;
}

.app-version {
	width: 100%;
	text-align: center;
	padding-bottom: 0.127rem;
	font-size: 0.75rem;
	opacity: 0.75;
}

#aside-user {
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	margin-top: 0.5rem;
	margin-bottom: 0.5rem;

	.aside-profile-picture {
		width: 2.5rem !important;
		height: 2.5rem !important;
		padding: 0;
		border-radius: 50%;
	}

	.name {
		width: 100%;
		font-weight: bold;
		display: none;
		text-align: center;
	}

	.passwordchanges {
		display: none;
		font-size: 0.75em;
		#changepassword {
			background: initial;
			&:hover {
				cursor: pointer;
				color: var(--ap-secondary);
			}
		}
	}
}

#aside.active {
	#aside-user {
		.aside-profile-picture {
			width: 4rem !important;
			height: 4rem !important;

			border: 0.127rem solid var(--ap-primary-yiq);
		}

		.name {
			display: block;
		}
		.passwordchanges {
			display: block;
		}
	}
}

.ag-root {
	// .ag-body-viewport .ag-center-cols-container {
	// 	//min-width: 100% !important;
	// }

	.ag-cell:not(.ag-cell-focus) {
		border-right: 1px solid #d9dcde !important;
	}

	.ag-cell-inline-editing {
		padding: 0 !important;
	}
}

.badge {
	border: 1px solid rgba(0, 0, 0, 0.3);
}

.ag-grid-center {
	.ag-cell {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

//Correction des filtres qui ne prennent pas toute la largeur en compte
.ag-input-text-wrapper {
	flex-grow: 1;
}



.select2-container {
	display: block !important;

	.select2-results__option {
		min-height: 32px;
	}

	.select2-selection {
		min-height: calc(1.5em + 0.75rem + 2px) !important;

		display: flex !important;
		align-items: center;

		border-radius: 0 !important;

		border: 1px solid #d4d7db !important;

		.select2-selection__arrow {
			height: 100% !important;
			width: 30px;
		}
	}

	.select2-selection__rendered {
		display: flex !important;
		align-items: center;
		flex-wrap: wrap;
		padding: 5px !important;
	}

	.select2-selection__choice {
		margin: 2px !important;
		background: var(--ap-grey-200) !important;
		border: 1px solid var(--ap-grey-400) !important;
	}
}

.ag-body-viewport {
	.ag-cell {
		.select2-container {
			height: 100%;
			width: 100%;

			.select2-selection {
				min-height: auto !important;
				height: 100%;
				//	border: 0 !important ;
				background-color: white !important ;
			}

			.select2-selection__arrow {
				display: none;
			}
		}
	}
}

/* -------------------------------------------------------------------------- */
/*                                   Facture                                  */
/* -------------------------------------------------------------------------- */

.bill.creation {
	.provider-block {
		background: var(--ap-grey-50);
		border: 1px solid var(--ap-grey-300);
		display: flex;
		align-items: center;
		padding: 15px 30px;

		.provider-img {
			width: 150px;
			border-right: 1px solid var(--ap-grey-300);
			margin-right: 10px;
			padding-right: 10px;
		}
	}

	.society-name {
		font-weight: bold;
		font-size: 1.25rem;
	}

	.society-phone {
		font-size: 0.85rem;
	}

	.bill-number {
		font-size: 1.5rem;
		text-align: center;
	}

	.orders-numbers,
	.delivery-notes-numbers,
	.work-orders-numbers {
		max-height: 100px;
		overflow: auto;
	}

	.bill-detail {
		background: var(--ap-grey-50);

		margin-left: 3%;
		margin-right: 3%;
		width: 50%;
		border: 1px solid var(--ap-grey-300);
		flex-grow: 1;
		tr {
			td {
				padding: 3px 10px 3px 10px;
				border: 1px solid var(--ap-grey-300);
				border-collapse: collapse;
			}
			td:first-child {
				text-align: right;
			}
		}
	}

	.customer-block {
		background: var(--ap-grey-50);
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-grow: 1;
		padding: 15px;
		margin-left: 3%;
		margin-right: 3%;
		border: 1px solid var(--ap-grey-300);

		.title {
			font-size: 1.25rem;
			border-bottom: 1px solid var(--ap-grey-300);
			width: 100%;
			text-align: center;
			margin-bottom: 5px;
		}

		.society-name {
			font-size: 1.1rem;
		}
	}

	.observation {
		background: var(--ap-grey-50);
		border: 1px solid var(--ap-grey-300);

		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 5px;

		margin-left: 3%;
		margin-right: 3%;
		width: 50%;

		.title {
			font-size: 1.25rem;
			border-bottom: 1px solid var(--ap-grey-300);
			width: 100%;
			text-align: center;
		}

		textarea {
			width: 100%;
			height: 100px;
		}
	}

	#totals {
		font-size: 0.8rem;
		width: 80px * 3 + 2px;
		padding: 0.25rem;

		background: var(--ap-grey-50);
		border: 1px solid var(--ap-grey-300);

		.total-excl-tax,
		.total-vat,
		.total-incl-tax {
			display: flex;
			justify-content: space-between;

			.label {
				text-align: right;
				margin-right: 5px;
			}
		}

		.total-incl-tax {
			margin-top: 2px;
			border-top: 1px solid black;
			padding-top: 2px;

			font-size: 1.1em;
			font-weight: bold;
		}
	}
}

//les DatePicker dans les modals ne sont pas au premier plan
.daterangepicker {
	z-index: 20000 !important;
}


#aside-top-content {
	z-index: 10;
}

.ag-cell.ag-cell-focused-grey {
	color: var(--ap-grey-500);
}

// Fichiers import.helper.ts

input[type="file"]#files-input {
	cursor: pointer;
}

input[type="file"]#files-input + .custom-file-label::after {
	content: "📁 Parcourir";
}

li.pattern::marker {
	content: '🔗';
}

.alert-error li::marker {
	content: '❌';
}

div.modal.importer .modal-dialog {
	max-width: 45%;
}
