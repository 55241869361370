.fix-container{
    position: relative
}

.fix-content{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:5px;
}


#modal-more-options{

    .form-control{
        border-color : #adadad;
    }
    
    .w-15{
        max-width:15%;
        min-width:15%;
        width:15%;
    }

    .text-underline{
        text-decoration : underline;
    }

    .dropdown-menu{
        ul{
            list-style: none;
            padding: 0;
            padding-left:15px;
        }
    }

}

#modal-list-recall{

    .ag-grid-circle{

        position: absolute;
        width: 15px;
        height: 15px;
        top: 5px;
        margin-right: 6px;
        border-radius: 50%;
        display: inline-block;

    }

    .ag-grid-circle-text{
        padding-left:20px;
    }

}


.w-15{
    max-width:15%;
    min-width:15%;
    width:15%;
}

.listRecall{
     
    #recallList{

        list-style: none;
        padding: 0;
        height: 100%;
        overflow: hidden;

        #event{
            background-color : #dddddd;
            padding: 3px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 5px;
            margin-bottom: 5px;
        }

    }

}

.listCalendar{

    #calendarList{

        list-style: none;
        padding: 0;
        height: 100%;

        .calendars-item{
            position: relative;

            .button-action{

                position: absolute;
                background-color: white;
                right: 0;
                top: 0;
                margin-top: 0.25rem;
                opacity: 0;

            }

            &:hover{
                .button-action{
                    opacity: 1;
                }
            }

        }

        ul{
            list-style: none;
            padding: 0;
            padding-left:15px;
   
        }
    }

}

#ag-grid-list{

    .ag-status-bar{
        display: none;
    }

    .ag-grid-circle{

        position: absolute;
        width: 15px;
        height: 15px;
        top: 5px;
        margin-right: 6px;
        border-radius: 50%;
        display: inline-block;

    }

}

.tui-full-calendar-vlayout-container{

    > div:not(.tui-full-calendar-splitter){
        min-height:34px !important;
    }
    
}

.btn-round-calendar{
    border-radius: 999em;
    height: 30px;
    line-height: 1;
    text-align: center;
}

.btn-view{
    border-radius: 999em;
    width: 100px;
    height: 30px;
    line-height: 1;
    text-align: center;
}

.btn-today{
    border-radius: 999em;
    width: 110px;
    height: 30px;
    line-height: 1;
    text-align: center;
}

.font-bold{
    font-weight: bold;
}

.no-scroll{
    overflow: hidden !important;
}

.is-mobile{

    .tui-full-calendar-allday-left,
    .tui-full-calendar-timegrid-left{
        width:22px !important;
    }

    .tui-full-calendar-timegrid-right,
    .tui-full-calendar-dayname-leftmargin{
        margin-left:22px !important;
    }

    .tui-full-calendar-month-dayname,
    .tui-full-calendar-dayname-container{
        height: 20px !important;
    }

    .tui-full-calendar-month-dayname-item,
    .tui-full-calendar-dayname {
        
        line-height: 14px !important;

        *{
            font-size: 15px !important;
            line-height: inherit !important;
        }
    }

    .tui-full-calendar-month{
        [data-panel-index="0"]{
            height: 20px !important;
            min-height: 20px !important;
        }
    }
    
    .tui-full-calendar-floating-layer{

        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        
        &.d-block{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            .tui-full-calendar-popup-detail{
                width: 100%;
                height: 100%;
                background: white;
            }
        }

    }

    .modal-fullscreen{

        margin: 0;
        height: 100%;
        width: 100%;

        .modal-content{
            border : none;
            height: 100%;
            width: 100%;          
        }

    }

    .header{
        height: 3.25rem;
        [data-toggle="dropdown"]{
            line-height: 3.25rem;
            min-width : 50px;
            text-align: center;
        }

        .btn-group{
            .btn{
                padding : 0;
                line-height: 3.25rem;
            }
        }

        .title,
        >.icon{
            line-height: 3.25rem;
        }
    }

    .listRecall{
        padding:0 !important;
    }

}

#modal-add-calendar{

    input[disabled] ~ label{

        cursor: not-allowed !important;

    }

}

.icon{
    line-height: inherit;
}
